import { createTheme } from "@mui/material/styles";
import Typography from "./typography";

const commonCell = {
  "&:not(:last-of-type)": {
    position: "relative",
    "&:after": {
      position: "absolute",
      content: '""',
      width: 1,
      height: "calc(100% - 30px)",
      right: 0,
      top: 16,
    },
  },
};

const fontFamily = "Inter var !important";

const Theme = createTheme({
  typography: Typography(fontFamily),
  breakpoints: {
    values: {
      xs: 0,
      xsm: 350,
      sm: 768,
      md: 1024,
      lg: 1266,
      xl: 1440,
      xxl: 1740,
      xxxl: 3000,
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#edf3ff",
          color: "black",
          fontWeight: 800,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          fontWeight: 400,
          color: "rgb(29, 38, 48)",
        },
        head: {
          fontSize: "0.85rem",
          fontWeight: 700,
          textTransform: "uppercase",
          ...commonCell,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "&.striped .MuiTableRow-root": {
            "&:nth-of-type(even)": {
              backgroundColor: "#f5f5f5", // You can change the background color for striped rows as needed
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-of-type": {
            "& .MuiTableCell-root": {
              borderBottom: "none",
            },
          },
          "& .MuiTableCell-root": {
            "&:last-of-type": {
              paddingRight: 24,
            },
            "&:first-of-type": {
              paddingLeft: 24,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 14,
        },
        notchedOutline: {
          borderColor: "#DBE0E5",
          borderRadius: "8px",
        },
        inputSizeSmall: {
          padding: "10px 10px 10px 12px",
        },
        inputMultiline: {
          padding: 0,
        },
        root: {
          "& svg": {
            color: "#DBE0E5",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DBE0E5",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid #DBE0E5`,
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "0.75rem",
          color: "rgb(29, 38, 48)",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            borderRadius: 8,
            border: "1px solid #DBE0E5",
            backgroundColor: "#fff",
          },
          "& .MuiInputLabel-root": {
            color: "#6B7280",
          },
          "& .MuiSelect-icon": {
            color: "#6B7280",
            right: 10,
          },
        },
      },
    },
  },
});

export default Theme;
