import { forwardRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { dispatch, useSelector } from "../../store";
import {
  useMediaQuery,
  ListItemButton,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import {
  activeComponent,
  openComponentDrawer,
} from "../../store/reducers/menu";

// sidebar components
const NavItem = ({ item }) => {
  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const { pathname } = useLocation();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const isSelected = openItem.includes(item.id) || pathname.includes(item.url);

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link {...props} to={item.url} target={itemTarget} ref={ref} />
    )),
  };

  useEffect(() => {
    if (pathname.includes(item.url) || pathname === item.url) {
      dispatch(activeComponent({ openComponent: item.id }));
    }
  }, [pathname, item.url, item.id]);

  const itemHandler = (id) => {
    dispatch(activeComponent({ openComponent: id }));
    matchesMD && dispatch(openComponentDrawer({ componentDrawerOpen: false }));
  };

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
      sx={{
        padding: "5px 0px",
        pl: drawerOpen ? "30px" : "30px",
        mb: 0.5,
        background: isSelected ? "#3C4048 !important" : "transparent", // Change background color for active item
        color: isSelected ? "white" : "rgba(255, 255, 255, 0.7)",
        "&:hover": {
          background: isSelected
            ? "#3C4048 !important"
            : "rgba(60, 64, 72, 0.5)", // Change background color for hover
        },
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: "0px",
          color: isSelected ? "white !important" : "gray",
        }}
      >
        {item.icon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="h6"
            sx={{
              maxWidth: "fit-content",
              padding: "8px 15px",
              borderRadius: "10px",
              pl: drawerOpen ? "20px" : "50px",
              color: isSelected ? "white" : "rgba(255, 255, 255, 0.7)",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            {item?.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default NavItem;
