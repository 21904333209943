import { HEADER_HEIGHT } from "../helper";
import logo1 from "../../asstes/images/logo1.png";
import logo2 from "../../asstes/images/logo2.png";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

// sidebar handle width and display sidebar logo
const DrawerHeader = ({ open }) => {
  return (
    <Box>
      <Box
        sx={{
          minHeight: HEADER_HEIGHT,
          width: "100%",
          textAlign: open ? "auto" : "center",
          padding: "30px 0px 10px 0px",
          paddingLeft: open ? "30px" : 0,
        }}
      >
        <Link to="https://replyrush.com" target="_blank">
          <img
            src={open ? logo1 : logo2}
            width={open ? "auto" : "50"}
            alt="reply rush logo"
          />
        </Link>
      </Box>
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
        }}
      />
    </Box>
  );
};

export default DrawerHeader;
