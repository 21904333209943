import { Box, Container, LinearProgress, Toolbar, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { DRAWER_WIDTH } from "../helper";
import MainDrawer from "./MainDrawer";
import Menuitems from "../../menu-items";
import { Suspense } from "react";
import Breadcrumbs from "./Breadcrumbs";

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
}));

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

const CommonLayoutWithAuth = ({ layout = "blank" }) => {
  const { items } = Menuitems();

  return (
    <>
      {layout === "landing" && (
        <Suspense fallback={<Loader />}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              backgroundColor: "rgb(248, 249, 250)",
              paddingTop: "20px",
            }}
          >
            <Header />
            <MainDrawer />
            <Box
              component="main"
              sx={{
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                flexGrow: 1,
                p: { xs: 0, md: 0 },
              }}
            >
              <Box
                component="main"
                sx={{
                  width: `calc(100% )`,
                  flexGrow: 1,
                }}
              >
                <Toolbar
                  sx={{
                    mt: "inherit",
                    mb: "inherit",
                  }}
                />
                {items && (
                  <Breadcrumbs
                    navigation={items}
                    title
                    titleBottom
                    card={false}
                    divider={false}
                  />
                )}
              </Box>
              <Container
                maxWidth={false}
                sx={{
                  xs: 0,
                  position: "relative",
                  minHeight: "calc(100vh - 280px)",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 1920,
                  paddingLeft: "32px",
                  paddingRight: "32px",
                }}
              >
                <Outlet />
              </Container>
            </Box>
          </Box>
        </Suspense>
      )}
      {layout === "blank" && <Outlet />}
    </>
  );
};

export default CommonLayoutWithAuth;
