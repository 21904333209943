import { combineReducers } from "@reduxjs/toolkit";
import menu from "./menu";
import snackbar from "./snackbar";

const reducer = combineReducers({
  menu,
  snackbar,
});

export default reducer;
