import Menuitems from "../../menu-items";
import { List, ListItem } from "@mui/material";
import NavItem from "./NavItem";

// sidebar components is called here
const DrawerContent = () => {
  const { items } = Menuitems();

  return (
    <>
      <List sx={{ color: "white", mt: 0 }}>
        {items?.map((item, index) => (
          <ListItem
            key={item?.id}
            disablePadding
            sx={{
              margin: 0,
            }}
          >
            <NavItem key={item.id} item={item} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default DrawerContent;
