import { Box, Drawer, styled, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useDispatch, useSelector } from "../../store";
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH, ThemeMode } from "../helper";
import DrawerHeader from "./DrawerHeader";
import { openDrawer } from "../../store/reducers/menu";
import DrawerContent from "./DrawerContent";

// sidebar menu display
const MainDrawer = ({ window }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);
  const drawerContent = useMemo(() => <DrawerContent />, []);

  const drawerHeader = useMemo(
    () => <DrawerHeader open={drawerOpen} />,
    [drawerOpen]
  );

  const container = window ? () => window().document.body : undefined;

  const drawerMixin = (open) => ({
    background: "linear-gradient(180deg, #000000 0%, #252525 100%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: open ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
    borderRight: open
      ? `1px dashed ${
          theme.palette.secondary[
            theme.palette.mode === ThemeMode.DARK ? 200 : 400
          ]
        }`
      : "none",
    boxShadow:
      open || theme.palette.mode === ThemeMode.DARK
        ? "0px 8px 24px rgba(19, 25, 32, 0.08)"
        : "none",
  });

  const MiniDrawerStyled = styled(Drawer)(({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...drawerMixin(open),
    "& .MuiDrawer-paper": drawerMixin(open),
  }));

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        zIndex: 1200,
        paddingRight: "0px important",
      }}
      aria-label="mailbox folders"
    >
      {!downLG ? (
        <MiniDrawerStyled variant="permanent" open={drawerOpen}>
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={drawerOpen}
          onClose={() => dispatch(openDrawer(!drawerOpen))}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              background:
                "linear-gradient(180deg, #000000 0%, #252525 100%) !important",
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
              borderRight: `1px solid ${theme.palette.divider}`,
              boxShadow: "inherit",
            },
          }}
        >
          {drawerHeader}
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default MainDrawer;
