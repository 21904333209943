import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import CommonLayout from "../Layouts/CommonLayout";
import CommonLayoutWithAuth from "../Layouts/CommonLayoutWithAuth";
import { CircularProgress } from "@mui/material";

const AuthLogin = lazy(() => import("../Pages/Login"));
const Dashboard = lazy(() => import("../Pages/Dashboard"));
const AllUsers = lazy(() => import("../Pages/AllUsers"));
const PaidUsers = lazy(() => import("../Pages/PaidUsers"));
const DeleteUsers = lazy(() => import("../Pages/DeleteUsers"));
const Setting = lazy(() => import("../Pages/Settings"));
const Plans = lazy(() => import("../Pages/Plans"));
const User_enable_post = lazy(() =>
  import("../Pages/AllUsers/User_enable_post")
);
const PostReelsEnabledPost = lazy(() =>
  import("../Pages/Post_Reels_enabled_post/PostReelsEnabledPost")
);

const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const lazyLoad = (Component) => (
  <Suspense
    fallback={
      <div style={loaderStyle}>
        <CircularProgress />
      </div>
    }
  >
    <Component />
  </Suspense>
);

const AuthRoutes = createBrowserRouter([
  {
    path: "/",
    element: <CommonLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/auth/login" replace />,
      },
      {
        path: "/auth",
        element: <CommonLayout />,
        children: [
          {
            path: "login",
            element: lazyLoad(AuthLogin),
          },
        ],
      },
      {
        path: "/",
        element: <CommonLayoutWithAuth layout="landing" />,
        children: [
          {
            path: "dashboard",
            element: lazyLoad(Dashboard),
          },
          {
            path: "all-users",
            element: lazyLoad(AllUsers),
          },
          {
            path: "paid-users",
            element: lazyLoad(PaidUsers),
          },
          {
            path: "delete-users",
            element: lazyLoad(DeleteUsers),
          },
          {
            path: "post-reels-enabled-users",
            element: lazyLoad(PostReelsEnabledPost),
          },
          {
            path: "setting",
            element: lazyLoad(Setting),
          },
          {
            path: "plan",
            element: lazyLoad(Plans),
          },
          {
            path: "all-users/user-enable-post",
            element: lazyLoad(User_enable_post),
          },
        ],
      },
      // {
      //   path: "*",
      //   element: <Error404 path="/auth/login" />,
      // },
      // {
      //   path: "/construction",
      //   element: lazyLoad(UnderConstruction),
      // },
    ],
  },
]);

export { AuthRoutes };
