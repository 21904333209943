import { Fragment } from "react";
import "./App.css";
import { AuthRoutes } from "./Routes/AllRoutes";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { ThemeProvider } from "@mui/material";
import Theme from "./Component/Theme";
import { QueryClient, QueryClientProvider } from "react-query";
import CustomSnackbar from "./Component/CustomSnackbar";

function App() {
  const queryClient = new QueryClient();
  return (
    <Fragment>
      <ThemeProvider theme={Theme}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <RouterProvider router={AuthRoutes} />
            <CustomSnackbar />
          </Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
