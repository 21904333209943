import {
  Home2,
  Profile2User,
  ProfileDelete,
  ReceiptItem,
  Setting2,
  User,
  Youtube,
} from "iconsax-react";

const mainItems = [
  {
    id: "Dashboard",
    title: "Dashboard",
    type: "item",
    url: "/dashboard",
    icon: <Home2 variant="Bold" />,
  },
  {
    id: "AllUsers",
    title: "All Users",
    type: "item",
    url: "/all-users",
    icon: <Profile2User variant="Bold" />,
    children: [
      {
        id: "UserEnablePost",
        title: "User Enable Post",
        type: "sub-item", // Use 'sub-item' for clarity
        url: "/all-users/user-enable-post",
        icon: <Profile2User variant="Bold" />,
      },
    ],
  },
  {
    id: "PaidUsers",
    title: "Paid Users",
    type: "item",
    url: "/paid-users",
    icon: <User variant="Bold" />,
  },
  {
    id: "DeletedUsers",
    title: "Deleted Users",
    type: "item",
    url: "/delete-users",
    icon: <ProfileDelete variant="Bold" />,
  },
  {
    id: "Posts/ReelsEnabled",
    title: "Posts / Reels Enabled",
    type: "item",
    url: "/post-reels-enabled-users",
    icon: <Youtube variant="Bold" />,
  },
  {
    id: "Settings",
    title: "Settings",
    type: "item",
    url: "/setting",
    icon: <Setting2 variant="Bold" />,
  },
  {
    id: "PlanPackage",
    title: "Plans Packages",
    type: "item",
    url: "/plan",
    icon: <ReceiptItem variant="Bold" />,
  },
];

export default mainItems;
