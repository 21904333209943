import React, { useState, startTransition } from "react";
import { Avatar, Box, Typography, Menu, MenuItem } from "@mui/material";
import Avatar1 from "../../asstes/images/avatar.png";
import { ArrowDown2, ArrowUp2, Logout } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// navbar logout button
const HeaderContent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const getUserEmail = localStorage.getItem("ADMINEMAIL");
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setActiveMenuItem("logout");
    handleConfirmLogout();
  };

  const handleConfirmLogout = () => {
    localStorage.removeItem("ACCESSTOKEN");
    localStorage.removeItem("ADMINEMAIL");
    localStorage.removeItem("ROLE");
    sessionStorage.clear("ACCESSTOKEN");
    sessionStorage.clear("ADMINEMAIL");
    sessionStorage.clear("ROLE");
    Cookies.remove("ACCESSTOKEN");
    Cookies.remove("ADMINEMAIL");
    Cookies.remove("ROLE");
    startTransition(() => {
      navigate("/");
    });
  };

  return (
    <>
      <Box sx={{ flexShrink: 0, pr: 3 }}>
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleClick}
        >
          <Avatar alt="profile user" src={Avatar1} size="34" />
          <Typography sx={{ fontSize: "16px", fontWeight: "600", m: 1 }}>
            {(() => {
              const emailUsername = getUserEmail?.split("@")[0];
              const capitalizedUsername =
                emailUsername?.charAt(0)?.toUpperCase() +
                emailUsername?.slice(1);
              return capitalizedUsername;
            })()}
          </Typography>
          {anchorEl ? (
            <ArrowUp2 size="20" color="#000000" variant="TwoTone" />
          ) : (
            <ArrowDown2 size="20" color="#000000" variant="TwoTone" />
          )}
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            textAlign: "center",
            borderRadius: "5px",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            width: "180px",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleLogout}
          sx={{
            justifyContent: "start",
            fontSize: "15px",
            backgroundColor:
              activeMenuItem === "logout" ? "rgba(0, 0, 0, 0.05)" : "inherit",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.05)", // Change this to your desired hover color
            },
            color: "red",
          }}
        >
          <Logout
            size="18"
            color="#FF4500"
            style={{ marginRight: "5px" }}
            variant="Bold"
          />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderContent;
