import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openItem: ["dashboard"],
  openComponent: "buttons",
  selectedID: null,
  drawerOpen: true,
  componentDrawerOpen: true,
  menu: {},
  error: null,
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

export default menu.reducer;

export const {
  openDrawer,
  activeItem,
  activeComponent,
  openComponentDrawer,
  hasError,
  openItem,
} = menu.actions;
