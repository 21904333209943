import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { ArrowRight2, Buildings2, Home3 } from "iconsax-react";

// every page title display
const Breadcrumbs = ({
  card,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const [main, setMain] = useState();
  const [item, setItem] = useState();
  const [subitem, setSubItem] = useState();

  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: "2rem",
    height: "1rem",
    color: theme.palette.secondary.main,
  };

  const getCollapse = useCallback(
    (menu) => {
      menu.forEach((collapse) => {
        // Recursively handle children based on type

        if (collapse.children) {
          getCollapse(collapse.children);
        }

        if (collapse.type === "collapse") {
          if (collapse.url === location.pathname) {
            setMain(collapse);
            setItem(null);
            setSubItem(null);
          }
        } else if (collapse.type === "item") {
          if (collapse.url === location.pathname) {
            setMain(null);
            setItem(collapse);
            setSubItem(null);
          }
        } else if (collapse.type === "subitem") {
          if (location.pathname.includes(collapse.url)) {
            setMain(null);
            setItem(null);
            setSubItem(collapse);
          }
        }
      });
    },
    [location.pathname]
  );

  useEffect(() => {
    getCollapse(navigation);
  }, [location.pathname, navigation, getCollapse]);

  // item separator
  const SeparatorIcon = separator || ArrowRight2;
  const separatorIcon = <SeparatorIcon size={15} color={"rgb(29, 38, 48)"} />;

  let breadcrumbContent = null;

  // collapse item
  if (main && main.type === "collapse" && main.breadcrumbs === true) {
    const CollapseIcon = main.icon || Buildings2;
    breadcrumbContent = (
      <Grid
        container
        direction={rightAlign ? "row" : "column"}
        justifyContent={rightAlign ? "space-between" : "flex-start"}
        alignItems={rightAlign ? "center" : "flex-start"}
        spacing={0.5}
        px={4}
        mt={3}
      >
        <Grid item>
          <MuiBreadcrumbs
            aria-label="breadcrumb"
            maxItems={maxItems || 8}
            separator={separatorIcon}
          >
            <Typography
              component={Link}
              to="/dashboard"
              variant="h6"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 400,
                color: theme.palette.text.primary,
              }}
            >
              {icons && <CollapseIcon style={iconSX} />}
              {!icons && "Home"}
            </Typography>
            <Typography
              component={Link}
              to={document.location.pathname}
              variant="h6"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 400,
                color: theme.palette.secondary.main,
              }}
            >
              {icons && <CollapseIcon style={iconSX} />}
              {main.title}
            </Typography>
          </MuiBreadcrumbs>
        </Grid>
        {title && titleBottom && (
          <Grid item sx={{ mt: card === false ? 0 : 1 }}>
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: "22px" }}>
              {main.title}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  // items
  if (item && item.type === "item") {
    const ItemIcon = item.icon || Buildings2;
    const hasChildren = item.children && item.children.length > 0;
    const currentUrl = location.pathname;

    breadcrumbContent = (
      <Grid
        container
        direction={rightAlign ? "row" : "column"}
        justifyContent={rightAlign ? "space-between" : "flex-start"}
        alignItems={rightAlign ? "center" : "flex-start"}
        spacing={0.5}
        px={4}
        my={3}
      >
        <Grid item>
          <MuiBreadcrumbs
            aria-label="breadcrumb"
            maxItems={maxItems || 8}
            separator={
              !item.url || item.url !== "/dashboard" ? (
                <>{separatorIcon}</>
              ) : null
            }
          >
            <Typography
              component={Link}
              to="/dashboard"
              variant="h6"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 400,
                color: "rgb(29, 38, 48)",
              }}
            >
              {icons && <Home3 style={iconSX} />}
              {!icons && "Home"}
            </Typography>
            <Typography
              component={Link}
              to={item.url}
              variant="h6"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 400,
                color: "rgb(29, 38, 48)",
              }}
            >
              {!item.url || item.url !== "/dashboard" ? (
                <>
                  {icons && <ItemIcon style={iconSX} />}
                  {item.title}
                </>
              ) : null}
            </Typography>
            {hasChildren &&
              item.children
                .filter((child) => currentUrl.includes(child.url)) // Check if currentUrl includes the child's URL
                .map((child) => (
                  <Typography
                    key={child.id}
                    component={Link}
                    to={child.url}
                    variant="h6"
                    sx={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "rgb(29, 38, 48)",
                    }}
                  >
                    {icons && <child.icon style={iconSX} />}
                    {child.title}
                  </Typography>
                ))}
          </MuiBreadcrumbs>
        </Grid>

        {title && titleBottom && (
          <Grid item sx={{ mt: card === false ? 0 : 1 }}>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {item.title}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  // sub items

  if (subitem && subitem.type === "subitem") {
    const SubItemIcon = subitem.icon || Buildings2;
    breadcrumbContent = (
      <Grid
        container
        direction={rightAlign ? "row" : "column"}
        justifyContent={rightAlign ? "space-between" : "flex-start"}
        alignItems={rightAlign ? "center" : "flex-start"}
        spacing={0.5}
        px={4}
        mt={3}
      >
        <Grid item>
          <MuiBreadcrumbs
            aria-label="breadcrumb"
            maxItems={maxItems || 8}
            separator={separatorIcon}
          >
            <Typography
              component={Link}
              to="/dashboard"
              variant="h6"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 400,
                color: "textPrimary",
              }}
            >
              {icons && <Home3 style={iconSX} />}
              {!icons && "Home"}
            </Typography>
            <Typography
              component={Link}
              to={item.url}
              variant="h6"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 400,
                color: "textPrimary",
              }}
            >
              {icons && <SubItemIcon style={iconSX} />}
              {item.title}
            </Typography>
            <Typography
              component={Link}
              to={subitem.url}
              variant="h6"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 400,
                color: "textPrimary",
              }}
            >
              {icons && <SubItemIcon style={iconSX} />}
              {subitem.title}
            </Typography>
          </MuiBreadcrumbs>
        </Grid>

        {title && titleBottom && (
          <Grid item sx={{ mt: card === false ? 0 : 1 }}>
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: "22px" }}>
              {subitem.title}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
