import { AppBar, Box, Toolbar, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { openDrawer } from "../../store/reducers/menu";
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from "../helper";
import Menu from "../../asstes/images/menu.png";
import HeaderContent from "./HeaderContent";
import { useMemo } from "react";

// navbar is called here
const Header = () => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);
  const headerContent = useMemo(() => <HeaderContent />, []);

  const appBarStyles = {
    position: "fixed",
    elevation: 0,
    bgcolor: alpha(theme.palette.background.default, 0.8),
    backgroundColor: "rgb(248, 249, 250)",
    zIndex: 1200,
    boxShadow: "none",
    width: {
      xs: "100%",
      lg: drawerOpen
        ? `calc(100% - ${DRAWER_WIDTH}px)`
        : `calc(100% - ${MINI_DRAWER_WIDTH}px)`,
    },
    paddingRight: "0px !important",
  };

  const AppBarStyled = styled(AppBar)(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open
      ? {
          marginLeft: DRAWER_WIDTH,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          "& > .MuiToolbar-root": {
            paddingRight: "0 !important", // Remove right padding from Toolbar
          },
          paddingRight: 0,
        }
      : {
          width: `calc(100% - ${MINI_DRAWER_WIDTH}px)`,
          "& > .MuiToolbar-root": {
            paddingRight: "0 !important", // Remove right padding from Toolbar
          },
        }),
  }));

  const mainHeader = (
    <Toolbar
      sx={{
        px: { xs: 2, sm: 4.5, lg: 4 },
        pl: { lg: 6 },
        py: 3,
        justifyContent: "space-between",
        backgroundColor: "rgb(248, 249, 250)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        minHeight: "40px !important",
      }}
    >
      <Box
        aria-label="open drawer"
        onClick={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          ml: { xs: 0, lg: -2 },
          p: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          color: "secondary.main",
        }}
      >
        <img src={Menu} alt="Menu" />
      </Box>
      <Box sx={{ display: "flex", color: "black" }}>{headerContent}</Box>
    </Toolbar>
  );

  return (
    <>
      {!downLG ? (
        <AppBarStyled
          open={drawerOpen}
          sx={appBarStyles}
          style={{ paddingRight: "0px !important" }}
        >
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar sx={appBarStyles} style={{ paddingRight: "0px !important" }}>
          {mainHeader}
        </AppBar>
      )}
    </>
  );
};

export default Header;
